import React from "react"
import "./Footer.Styles.css"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  return (
    <>
      <section className="footer-section">
        <div className="container-fluid footer d-block">
          <div className="container-fluid footer-shadow">
            <footer className="pb-0">
              <div className="container m-auto">
                <div className="col-12 d-flex justify-content-center p-4 logo-container d-block d-sm-none "></div>

                <div className="row p-1 py-4 links-container text-center text-sm-left w-100 m-auto">
                  <div className="my-auto col-3 d-none d-sm-block align-middle">
                    <a href="/">
                      <StaticImage
                        src="../../../src/images/pave_logo_footer.png"
                        alt="Jump Mobile App"
                        height={70}
                        placeholder="blurred"
                      />
                    </a>
                  </div>

                  <div className="my-auto col-12 col-sm-2 mx-0 px-0 align-middle">
                    <ul className="">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/how-it-works/#top">How it Works</a>
                      </li>
                      <li>
                        <a href="/where-we-lend">Where we lend</a>
                      </li>
                      <li>
                        <a href="/faqs">FAQs</a>
                      </li>
                    </ul>
                  </div>
                  <div className="my-auto col-12 col-sm-2 mx-0 px-0">
                    <ul>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li>
                        <a href="https://login.pavecredit.com/">Log In</a>
                      </li>

                      <li>
                        <a href="https://application.pavecredit.com/">
                          Apply Now
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="my-auto col-12 col-sm-3 mx-0 px-0">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          href="https://pavecredit.com/privacy-policy"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://pavecredit.com/terms-of-use"
                        >
                          Terms &amp; Conditions
                        </a>
                      </li>
                      {/*
                      <li>
                        <a
                          target="_blank"
                          href="../../docs/Salt Point Finance Certificate 2020.pdf"
                        >
                          Licenses, Affiliations &amp; Regulatory
                        </a>
                      </li>

                  */}

                      <li>
                        <a href="/ccpa-information/">
                          Do not sell my information
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="my-auto col-2">
                    <div className="bnt-cotainer d-none d-sm-block">
                      <div className="col-12 py-2 ">
                        <StaticImage
                          src="../../../src/images/footer/footer3.png"
                          alt="Jump Mobile App"
                          width={150}
                          placeholder="tracedSVG"
                          blurredOptions="none"
                        />
                      </div>
                      <div className="col-12 py-2 ">
                        <StaticImage
                          src="../../../src/images/footer/footer2.png"
                          alt="Jump Mobile App"
                          width={150}
                          placeholder="tracedSVG"
                          blurredOptions="none"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="logos-container row d-flex justify-content-center d-block d-sm-flex justify-content-sm-around w-8"></div>

                <div className="row w-100 m-auto mobile-border-bottom">
                  <div className="text-center p-4 contact-info-container d-block d-sm-flex justify-content-sm-around  m-auto">
                    <p className="border-2 mb-3 m-sm-0 align-middle col-sm-5">
                      {" "}
                      <a href="tel:855-801-7283">1-855-801-PAVE (7283)</a>
                    </p>
                    <p className="d-block d-sm-none align-middle">
                      Pave Credit Operational Dept.,
                    </p>
                    <p className="d-block d-sm-none align-middle">
                      P.O. Box 6483, Santa Rosa, CA 95406
                    </p>
                    <p className="d-none d-sm-block align-middle col-sm-6">
                      Pave Credit Operational Dept., P.O. Box 6483, Santa Rosa,
                      CA 95406
                    </p>
                  </div>
                </div>

                <div className="row text-center legal-container p-4 w-100 m-auto">
                  <small>
                    Pave Credit is a brand of Salt Point Finance, a wholly owned
                    subsidiary of Kashia Services, an economic arm and
                    instrumentality of the Kashia Band of Pomo Indians of the
                    Stewarts Point Rancheria, a federally recognized sovereign
                    Indian tribe located in the United States. The Kashia
                    Lending Commission is the regulatory agency responsible for
                    regulation of Salt Point Finance. Salt Point Finance is
                    licensed and regulated by the Kashia Lending Commission
                    pursuant to the Kashia Band of Pomo Indians’ Ordinance 12:
                    Consumer Finance Business Regulation.
                    <br />
                    <br />
                    Our lines of credit are subject to terms and conditions. Not
                    all applicants will qualify for a line of credit. Lines of
                    credit are not currently available to residents of
                    Connecticut, Georgia, Maryland, New Hampshire, New Jersey,
                    New York, North Carolina, Pennsylvania, Virginia or Vermont.
                    <br />
                    <br />
                    Applicants will be asked to verify all information provided
                    during the application process, per underwriting and credit
                    reporting requirements. Additional permissions may be
                    requested during the term of your relationship with Pave.
                    <br />
                    <br />
                    All lines of credit are subject to final approval by Pave
                    Credit prior to funding, which may include review or
                    investigation of additional underwriting or account
                    information. Final funded amounts may differ based on this
                    review. Customer is under no obligation to accept any offer
                    of credit from Pave Credit. Please review our Privacy
                    Policy, which governs our information sharing practices, and
                    our Terms of Use, which governs your use of our website.
                    <br />
                    <br />
                    Please review our Privacy Policy, which governs our
                    information sharing practices, and our Terms of Use, which
                    governs your use of our website.
                  </small>
                </div>
                <div className="row copyright-container text-center p-3 d-flex justify-content-center w-100 m-auto">
                  <small>
                    &copy; Copyright 2022. Pave Credit All Rights Reserved
                  </small>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </>
  )
}
