import React from "react"
import {
  Container,
  Button,
  Col,
  Media,
  Image,
  Row,
  Form,
} from "react-bootstrap"
import Footer from "../components/Layout/Footer"
import NavBarLight from "../components/Layout/navBar/NavBarLight"

const ContactUs = () => (
  <>
    <NavBarLight />
    <Container fluid className="home-container p-0 mb-5">
      <Container fluid className="py-5 py-md-1">
        <Container fluid="xl" className="py-xl-1">
          <h1 className="pt-3 mt-4 h1-header"> Contact Us </h1>{" "}
          <Row>
            {" "}
            <Col md={{ order: "first", span: 6 }} className="pl-xl-4">
              <p>
                Pave Credit is here for you whenever you need assistance.
                Whether you have questions about your current line of credit
                account, or you’d like more info about your line of credit
                options before applying, or you’re just curious about what we
                offer, we’re happy to help!
              </p>{" "}
              <Form id="contactForm">
                <Form.Group controlId="Name">
                  <Form.Label> Full Name </Form.Label>{" "}
                  <Form.Control type="text" placeholder="Your Name" />
                </Form.Group>{" "}
                <Form.Group controlId="Email">
                  <Form.Label> Email address </Form.Label>{" "}
                  <Form.Control type="email" placeholder="Enter email" />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.{" "}
                  </Form.Text>{" "}
                </Form.Group>{" "}
                <Form.Group controlId="Subject">
                  <Form.Label> Subject </Form.Label>{" "}
                  <Form.Control type="text" placeholder="Subject" />
                </Form.Group>{" "}
                <Form.Group controlId="Message">
                  <Form.Label> Message </Form.Label>{" "}
                  <Form.Control as="textarea" rows={3} />{" "}
                </Form.Group>{" "}
                <div className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-50 btn-dark"
                  >
                    Submit{" "}
                  </Button>{" "}
                </div>
              </Form>{" "}
            </Col>{" "}
            <Col
              lg={{ order: "last", span: 5 }}
              md={{ order: "first" }}
              className="mx-md-auto"
            >
              <Container className="">
                <Row>
                  <Col xs={12} className="">
                    <h3 className="m-0 h5-header"> Support Email </h3>{" "}
                    <p className="m-0">
                      <a
                        className="linkColor"
                        href="mailto:support@pavecredit.com"
                      >
                        support@pavecredit.com
                      </a>{" "}
                    </p>{" "}
                  </Col>{" "}
                </Row>{" "}
                <Row>
                  <Col xs={12} className="mt-3 ">
                    <h3 className="m-0 h5-header"> Phone </h3>{" "}
                    <p className="m-0">855-801-PAVE (7283)</p>{" "}
                  </Col>{" "}
                </Row>{" "}
                <Row>
                  <Col xs={12} className="mt-3">
                    <h3 className="m-0 h5-header "> Operating Hours </h3>{" "}
                    <p className="m-0"> Monday - Friday </p>{" "}
                    <p className="m-0"> 8 AM - 8 PM EST </p>{" "}
                  </Col>{" "}
                </Row>{" "}
                <Row className="pb-5">
                  <Col xs={12} className="mt-3">
                    <h3 className="m-0 h5-header"> Go old - school mail </h3>{" "}
                    <p className="m-0"> Pave Credit Operational Dept. </p>{" "}
                    <p className="m-0"> P.O. Box 6483, Santa Rosa, CA 95406 </p>{" "}
                  </Col>{" "}
                </Row>{" "}
              </Container>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Container>{" "}
    </Container>
    <Footer />
  </>
)

export default ContactUs
